// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit";

const initialUser = () => {
  const item = window.localStorage.getItem("userData");
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : {};
};

export const authSlice = createSlice({
  name: "authentication",
  initialState: {
    userData: initialUser(),
  },
  reducers: {
    handleLogin: (state, action) => {
      state.userData = action.payload;
      state[state.storageTokenKeyName] =
        action.payload[state.storageTokenKeyName];
      state[state.storageRefreshTokenKeyName] =
        action.payload[state.storageRefreshTokenKeyName];
      localStorage.setItem("userData", JSON.stringify(action.payload));
      localStorage.setItem(
        state.storageTokenKeyName,
        JSON.stringify(action.payload.accessToken)
      );
      localStorage.setItem(
        state.storageRefreshTokenKeyName,
        JSON.stringify(action.payload.refreshToken)
      );
    },
    handleLogout: (state) => {
      state.userData = {};
      state[state.storageTokenKeyName] = null;
      state[state.storageRefreshTokenKeyName] = null;
      localStorage.removeItem("userData");
      localStorage.removeItem(state.storageTokenKeyName);
      localStorage.removeItem(state.storageRefreshTokenKeyName);
    },
  },
});

export const { handleLogin, handleLogout } = authSlice.actions;

export default authSlice.reducer;
