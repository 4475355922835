// src/redux/store.js
import { configureStore } from '@reduxjs/toolkit';

// Your reducers go here
import authentication from "./authentication";
import kids from "./kids";
import code from "./code";

const store = configureStore({
  reducer: {
    authentication: authentication,
    kids: kids,
    code: code,
  },
});

export default store;