import { Link, useNavigate } from "react-router-dom";
import Logo from "../assets/images/logo.png";
import { useState } from "react";
import React from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Flatpickr from "react-flatpickr";
import axios from "axios";
import "flatpickr/dist/themes/material_blue.css";
import cogoToast from "cogo-toast";
import { useDispatch } from "react-redux";
import { handleLogin } from "../redux/authentication";
import InputPasswordToggle from "../components/Input";

const Register = () => {
  const [childrenFields, setChildrenFields] = useState([
    { name: "", dob: "", healthStatus: "" },
  ]);
  const [visible, setVisible] = useState(false);


  const navigate = useNavigate();
  const dispatch = useDispatch();
  const addRow = () => {
    setChildrenFields([
      ...childrenFields,
      { name: "", dob: "", healthStatus: "" },
    ]);
  };

  const deleteRow = (index) => {
    const updatedChildrenFields = [...childrenFields];
    updatedChildrenFields.splice(index, 1);
    setChildrenFields(updatedChildrenFields);
  };
  const validationSchema = yup.object().shape({
    fatherName: yup.string().required("اسم الأب مطلوب"),
    password: yup.string().min(8, 'كلمة المرور يجب ان تكون مكونة من 8 محارف').required("كلمة المرور مطلوبة"),
    c_password: yup.string().oneOf([yup.ref("password")], "كلمة المرو   يجب ان تكون متطابقة"),
    email: yup.string().email().required("البريد الالكتروني مطلوب"),
    fatherNumber: yup.string().min(14, 'رقم الهاتف يجب ان يكون مكون من 14 رقم').max(14).required("رقم الأب مطلوب"),
    motherName: yup.string().required("اسم الأم مطلوب"),
    // motherNumber: yup.string().min(14).max(14).required("رقم الأم مطلوب"),
    homeAddress: yup.string().required("عنوان المنزل مطلوب"),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      fatherName: "",
      password: "",
      email: "",
      fatherNumber: "",
      motherName: "",
      motherNumber: "",
      homeAddress: "",
    },
  });
  const handleChildChange = (index, field, value) => {
    const updatedChildrenFields = [...childrenFields];
    updatedChildrenFields[index][field] = value;
    setChildrenFields(updatedChildrenFields);
  };
  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }
  const onSubmit = (data) => {
    if (childrenFields.length > 0) {
      const hasEmptyChildFields = childrenFields.some((child) =>
        Object.values(child).some((value) => value === "")
      );
      if (hasEmptyChildFields) {
        cogoToast.warn("قم بادخال جميع البيانات الخاصة بكل طفل", {
          position: "bottom-left",
        });
      } else {
        const formData = new FormData();
        formData.append("fname", data.fatherName);
        formData.append("email", data.email);
        formData.append("phone", data.fatherNumber);
        formData.append("password", data.password);
        formData.append("lname", data.motherName);
        childrenFields.forEach((kid, index) => {
          formData.append(`kids[${index}][name]`, kid.name);
          formData.append(`kids[${index}][dob]`, kid.dob);
        });
        axios
          .post(
            "https://joycitybackend.bw-businessworld.com/api/v1/web/auth/register",
            formData
          )
          .then((response) => {
            const data = {
              ...response.data,
              accessToken: response.data.access_token,
              refreshToken: response.data.access_token,
              username: response.data.user_name,
            };
            dispatch(handleLogin(data));
            navigate("/view");
          })
          .catch((err) => {
            console.log(err);
            cogoToast.error(err?.response?.data?.messages[0], {
              position: "bottom-left",
            });
          });
      }
    } else {
      setVisible(true);
    }
  };



  return (
    <>
      <div className="top-section pt-5 pb-5">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <img src={Logo} width={"300px"} />
              <h1 className="mt-4">مرحبا بك في مدينة المرح</h1>
              <div className="bg-white form-wrap mt-5 p-4">
                <form
                  className="text-end"
                  dir="rtl"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <label className="form-label">اسم الاب</label>
                      <input
                        className="mt-2 form-control"
                        placeholder="ادخل اسم الاب"
                        type="text"
                        {...register("fatherName")}
                      />
                      {errors.fatherName && (
                        <small className="text-danger">
                          {errors.fatherName.message}
                        </small>
                      )}
                    </div>
                    <div className="col-md-6 mb-3">
                      <label className="form-label">رقم الاب</label>
                      <input
                        className="mt-2 form-control"
                        placeholder="00963912345678"
                        type="number"
                        {...register("fatherNumber")}
                      />
                      {errors.fatherNumber && (
                        <small className="text-danger">
                          {errors.fatherNumber.message}
                        </small>
                      )}
                    </div>
                    <div className="col-md-6 mb-3">
                      <label className="form-label">كلمة المرور</label>
                        <InputPasswordToggle className='mt-2 form-control' placeholder='' {...register('password')}/>
                      {errors.password && (
                        <small className="text-danger">
                          {errors.password.message}
                        </small>
                      )}
                    </div>
                    <div className="col-md-6 mb-3">
                      <label className="form-label">تاكيد كلمة المرور</label>
                        <InputPasswordToggle className='mt-2 form-control' placeholder='' {...register('c_password')}/>
                      {errors.c_password && (
                        <small className="text-danger">
                          {errors.c_password.message}
                        </small>
                      )}
                    </div>
                    <div className="col-md-6 mb-3">
                      <label className="form-label">البريد الالكتروني</label>
                      <input
                        className="mt-2 form-control"
                        placeholder="ادخل البريد الالكتروني"
                        type="email"
                        {...register("email")}
                      />
                      {errors.email && (
                        <small className="text-danger">
                          {errors.email.message}
                        </small>
                      )}
                    </div>
                    <div className="col-md-6 mb-3">
                      <label className="form-label">اسم الام</label>
                      <input
                        className="mt-2 form-control"
                        placeholder="ادخل اسم الام"
                        type="text"
                        {...register("motherName")}
                      />
                      {errors.motherName && (
                        <small className="text-danger">
                          {errors.motherName.message}
                        </small>
                      )}
                    </div>
                    <div className="col-md-6 mb-3">
                      <label className="form-label">رقم الام</label>
                      <input
                        className="mt-2 form-control"
                        placeholder="00963912345678"
                        type="number"
                        {...register("motherNumber")}
                      />
                      {errors.motherNumber && (
                        <small className="text-danger">
                          {errors.motherNumber.message}
                        </small>
                      )}
                    </div>
                    <div className="col-md-6 mb-3">
                      <label className="form-label"> عنوان المنزل</label>
                      <input
                        className="mt-2 form-control"
                        placeholder="ادخل  عنوان المنزل"
                        type="text"
                        {...register("homeAddress")}
                      />
                      {errors.homeAddress && (
                        <small className="text-danger">
                          {errors.homeAddress.message}
                        </small>
                      )}
                    </div>
                    <hr></hr>
                    <h5>اضافة طفل</h5>
                    {childrenFields.map((row, index) => {
                      return (
                        <div className="row border-bottom mt-3 pb-2 position-relative">
                          <button
                            className="delete-btn"
                            onClick={(e) => {
                              deleteRow(index);
                              e.preventDefault();
                            }}
                          >
                            X
                          </button>
                          <div className="col-md-6 mb-3">
                            <label className="form-label"> اسم الطفل</label>
                            <input
                              className="mt-2 form-control"
                              placeholder="ادخل  اسم الطفل"
                              type="text"
                              onChange={(e) =>
                                handleChildChange(index, "name", e.target.value)
                              }
                            ></input>
                          </div>
                          <div className="col-md-6 mb-3">
                            <label className="form-label">
                              {" "}
                              تاريخ ميلاد الطفل
                            </label>
                            <Flatpickr
                              className="mt-2 form-control"
                              placeholder="ادخل  تاريخ ميلاد الطفل"
                              options={{
                                dateFormat: "Y-m-d",
                                maxDate: new Date(),
                              }}
                              onChange={(e) =>
                                handleChildChange(index, "dob", formatDate(e))
                              }
                            />
                          </div>
                          <div className="col-md-12 mb-3">
                            <label className="form-label">
                              {" "}
                              الحالة الصحية للطفل
                            </label>
                            <input
                              className="mt-2 form-control"
                              placeholder="ادخل  الحالة الصحية للطفل"
                              type="textarea"
                              onChange={(e) =>
                                handleChildChange(
                                  index,
                                  "healthStatus",
                                  e.target.value
                                )
                              }
                            ></input>
                          </div>
                        </div>
                      );
                    })}
                    <button
                      className="add-btn mt-4"
                      onClick={(e) => {
                        addRow();
                        e.preventDefault();
                      }}
                    >
                      + اضف طفل
                    </button>
                    {visible && childrenFields?.length === 0 ? (
                      <small className="text-danger">
                        يرجى اضافة طفل على الاقل.
                      </small>
                    ) : null}
                    <button className="register-btn mt-4" type="submit">
                      تسجيل
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Register;
