import Logo from "../assets/images/logo.png";
import { useEffect, useState } from "react";
import React from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Flatpickr from "react-flatpickr";
import { useDispatch, useSelector } from "react-redux";
import ChildComponent from "../components/Child";
import {
  addReservation,
  addUser,
  deleteRes,
  getKids,
  getRes,
} from "../redux/kids";
import ClipLoader from "react-spinners/ClipLoader";
import { getUserData, isObjEmpty } from "../utility";
import { Link, useNavigate } from "react-router-dom";
import { handleLogout } from "../redux/authentication";
import Table from "react-bootstrap/Table";
import Accordion from "react-bootstrap/Accordion";
import Modal from "react-bootstrap/Modal";
import cogoToast from "cogo-toast";
import Bin from "../assets/images/bin.svg";
import { confirmAlert } from "../utility/alert";

const View = () => {
  const userData = getUserData();
  const navigate = useNavigate();
  const store = useSelector((state) => state.kids);
  const [showModal, setShowModal] = useState(false);
  const [childrenData, setChildren] = useState([]);
  const [picker, setPicker] = useState(formatDate(new Date()));
  const [pickerStart, setPickerStart] = useState(formatDate(new Date()));
  const [pickerEnd, setPickerEnd] = useState(formatDate(new Date()));
  const [pickerTStart, setPickerTStart] = useState(new Date().getTime());
  const [pickerTEnd, setPickerTEnd] = useState(new Date().getTime());
  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();

  const validationSchema = yup.object().shape({
    fname: yup.string().required("اسم الطفل مطلوب"),
    healthStatus: yup.string().required("الحالة الصحية للطفل مطلوبة"),
  });
  const validationSchema1 = yup.object().shape({
    child_id: yup.string().required("اسم الطفل مطلوب"),
  });
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      fname: "",
      healthStatus: "",
    },
  });

  const {
    register: register1,
    handleSubmit: handleSubmit1,
    reset: reset1,
    formState: { errors: errors1 },
  } = useForm({
    resolver: yupResolver(validationSchema1),
    defaultValues: {
      child_id: "",
    },
  });
  const handleModalOpen = () => {
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
    reset1({
      child_id: "",
    });
    setPickerStart(formatDate(new Date()));
    setPickerEnd(formatDate(new Date()));
  };
  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }
  const onSubmit = (data) => {
    if (picker && picker !== "NaN-NaN-NaN") {
      const formData = new FormData();
      formData.append("fname", data?.fname);
      formData.append("health_status", data?.healthStatus);
      formData.append("dob", picker);
      dispatch(addUser(formData));
      reset();
    } else {
      setVisible(true);
    }
  };
  const onSubmit1 = (data) => {
    if (pickerTStart && pickerStart) {
      const formData = new FormData();
      formData.append("child_id", data?.child_id);
      formData.append(
        "start_date_time",
        pickerStart +
          " " +
          new Date(pickerTStart)?.getHours()?.toString() +
          ":" +
          new Date(pickerTStart).getMinutes()?.toString()
      );
      formData.append("end_date_time", pickerStart + " " + pickerTEnd);
      dispatch(addReservation(formData));
      reset1({
        child_id: "",
      });
      handleModalClose();
    } else {
      cogoToast.error("قم بتعبئة جميع الحقول", { position: "bottom-left" });
    }
  };
  function addHour(picker) {
    if (picker) {
      const newTime = new Date(picker);
      const time = newTime.setHours(newTime.getHours() + 1);
      return time;
    }
  }

  const deleteReservation = (id) => {
    dispatch(deleteRes(id));
  };
  useEffect(() => {
    dispatch(getKids());
    dispatch(getRes());
  }, [dispatch]);

  useEffect(() => {
    if (store?.data) {
      setChildren([...store?.data]);
    }
  }, [store]);

  return (
    <>
      <div className="top-section pt-5 pb-5">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              {/* <img src={Logo} width={"300px"} /> */}
              <h1 className="mt-4">مرحبا بك في مدينة المرح</h1>
              <div className="bg-white form-wrap mt-5 p-4">
                {userData?.data ? (
                  <>
                    <h5 className="text-end mb-4">معلومات الحساب</h5>
                    <div
                      className="parent d-flex align-items-center justify-content-start"
                      dir="rtl"
                    >
                      <span class="badge rounded-pill text-capitalize ms-2">
                        {userData?.data?.user_name?.substr(0, 1)}
                      </span>
                    </div>
                    <div className="buttons text-end mt-4">
                      <button
                        className="btn btn-logout me-2"
                        onClick={() => {
                          dispatch(handleLogout());
                          navigate("/");
                        }}
                      >
                        تسجيل الخروج
                      </button>
                      <button
                        className="register-btn "
                        onClick={handleModalOpen}
                      >
                        حجز جديد
                      </button>
                    </div>
                    <Accordion className="mt-4">
                      <Accordion.Item eventKey="0">
                        <Accordion.Header dir="rtl">
                          عرض الحجوزات
                        </Accordion.Header>
                        <Accordion.Body>
                          {store?.reservations?.length ? (
                            <Table responsive dir="rtl" bordered hover>
                              <thead>
                                <tr>
                                  <th>اسم الطفل</th>
                                  <th>يوم الدخول </th>
                                  <th>وقت الدخول</th>
                                  <th>وقت الخروج</th>
                                  <th>اجراءات</th>
                                </tr>
                              </thead>
                              <tbody>
                                {store?.reservations?.map((item) => {
                                  return (
                                    <tr>
                                      <td>{item?.child?.fname}</td>
                                      <td>
                                        {item?.start_date_time
                                          ? item?.start_date_time?.substr(0, 10)
                                          : "غير محدد"}
                                      </td>
                                      <td>
                                        {item?.start_date_time
                                          ? item?.start_date_time?.substr(
                                              11,
                                              16
                                            )
                                          : "غير محدد"}
                                      </td>
                                      <td>
                                        {item?.end_date_time
                                          ? item?.end_date_time?.substr(11, 16)
                                          : "غير محدد"}
                                      </td>
                                      <td>
                                        <button
                                          className="btn"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            confirmAlert(
                                              item?.id,
                                              deleteReservation
                                            );
                                          }}
                                        >
                                          <img src={Bin} width={"16"} />
                                        </button>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </Table>
                          ) : (
                            <span className="badge bg-opacity text-warning">
                              لا يوجد حجوزات
                            </span>
                          )}
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>

                    <hr></hr>
                    <h5 className="text-end mb-4 mt-4">معلومات الاطفال</h5>
                    {store?.loading ? (
                      <div className="children">
                        {childrenData?.length ? (
                          childrenData.map((child, index) => (
                            <ChildComponent
                              formatDate={formatDate}
                              key={index}
                              name={child.fname}
                              id={child.id}
                              imageSrc={child.image}
                              dob={child.dob}
                              qrCodeSrc={child.qr_code}
                            />
                          ))
                        ) : (
                          <span className="badge p-2 bg-opacity text-warning">
                            لا يوجد أطفال
                          </span>
                        )}
                      </div>
                    ) : (
                      <ClipLoader
                        loading={true}
                        cssOverride={{
                          display: "block",
                          margin: "0 auto",
                          borderColor: "#9178F9",
                        }}
                        size={35}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                      />
                    )}

                    <form
                      className="text-end mt-4"
                      dir="rtl"
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <div className="row">
                        <hr></hr>
                        <h5>اضافة طفل</h5>
                        <div className="col-md-6 mb-3">
                          <label className="form-label"> اسم الطفل</label>
                          <input
                            className="mt-2 form-control"
                            placeholder="ادخل  اسم الطفل"
                            type="text"
                            {...register("fname")}
                          ></input>
                          {errors.fname && (
                            <small className="text-danger">
                              {errors.fname.message}
                            </small>
                          )}
                        </div>
                        <div className="col-md-6 mb-3">
                          <label className="form-label">
                            {" "}
                            تاريخ ميلاد الطفل
                          </label>
                          <Flatpickr
                            className="mt-2 form-control"
                            placeholder="ادخل  تاريخ ميلاد الطفل"
                            options={{
                              dateFormat: "Y-m-d",
                              maxDate: new Date(),
                            }}
                            value={picker}
                            onChange={(e) => setPicker(formatDate(e))}
                          />
                          {visible && picker === "NaN-NaN-NaN" ? (
                            <small className="text-danger">
                              تاريخ الميلاد مطلوب
                            </small>
                          ) : null}
                        </div>
                        <div className="col-md-12 mb-3">
                          <label className="form-label">
                            {" "}
                            الحالة الصحية للطفل
                          </label>
                          <input
                            className="mt-2 form-control"
                            placeholder="ادخل  الحالة الصحية للطفل"
                            type="textarea"
                            {...register("healthStatus")}
                          ></input>
                          {errors.healthStatus && (
                            <small className="text-danger">
                              {errors.healthStatus.message}
                            </small>
                          )}
                        </div>

                        <button className="register-btn mt-4" type="submit">
                          + اضف طفل
                        </button>
                      </div>
                    </form>
                  </>
                ) : (
                  <Link to={"/login"}>قم بتسجيل الدخول</Link>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={showModal} onHide={handleModalClose}>
        <Modal.Header closeButton dir="rtl">
          <Modal.Title>حجز جديد</Modal.Title>
        </Modal.Header>
        <Modal.Body dir="rtl">
          <form onSubmit={handleSubmit1(onSubmit1)}>
            <div className="row">
              <div className="col-md-6 mb-3">
                <label className="form-label"> اسم الطفل</label>
                <select
                  className="mt-2 form-control"
                  placeholder="ادخل  اسم الطفل"
                  {...register1("child_id")}
                >
                  <option value={""}>اختر طفل</option>
                  {store?.data?.map((item) => {
                    return (
                      <option key={item?.id} value={item?.id}>
                        {item?.fname}
                      </option>
                    );
                  })}
                </select>
                {errors1.child_id && (
                  <small className="text-danger">
                    {errors1.child_id.message}
                  </small>
                )}
              </div>
              <div className="col-md-6 mb-3">
                <label className="form-label"> تاريخ بداية الحجز</label>
                <Flatpickr
                  className="mt-2 form-control"
                  options={{
                    dateFormat: "Y-m-d",
                    minDate: "today",
                    maxDate: new Date().fp_incr(14),
                  }}
                  value={pickerStart}
                  onChange={(e) => {
                    setPickerStart(formatDate(e));
                    setPickerEnd(formatDate(e));
                  }}
                />
              </div>
              <div className="col-md-6 mb-3">
                <label className="form-label"> وقت بداية الحجز</label>
                <Flatpickr
                  className="mt-2 form-control"
                  options={{
                    enableTime: true,
                    noCalendar: true,
                    dateFormat: "H:i",
                    minTime: "08:00",
                    maxTime: "15:00",
                    time_24hr: true,
                  }}
                  value={pickerTStart}
                  onChange={(e) => {
                    setPickerTStart(e);
                  }}
                />
              </div>
              {/* <div className="col-md-6 mb-3">
                <label className="form-label"> تاريخ نهاية الحجز</label>
                <Flatpickr
                  className="mt-2 form-control"
                  options={{
                    dateFormat: "Y-m-d",
                    minDate:  'today',
                    maxDate: pickerStart,
                  }}
                  disabled
                  value={pickerEnd}
                  onChange={(e) => setPickerEnd(formatDate(e))}
                />
              </div> */}
              <div className="col-md-6 mb-3">
                <label className="form-label"> وقت نهاية الحجز</label>
                <Flatpickr
                  className="mt-2 form-control"
                  options={{
                    enableTime: true,
                    noCalendar: true,
                    dateFormat: "H:i",
                    time_24hr: true,
                    minTime: addHour(pickerTStart),
                    maxTime: "16:00",
                  }}
                  value={pickerTEnd}
                  onChange={(selectedDates, dateStr, instance) =>
                    setPickerTEnd(dateStr)
                  }
                />
              </div>
            </div>
            <div className="buttons mt-4">
              <button className="btn btn-primary btn-main ms-2" type="submit">
                حجز
              </button>
              <button
                className="btn btn-secondary ms-2"
                onClick={handleModalClose}
              >
                الغاء
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default View;
