import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import QRCode from "react-qr-code";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Flatpickr from "react-flatpickr";
import { useDispatch } from "react-redux";
import { deleteUser, updateUser } from "../redux/kids";
import { confirmAlert } from "../utility/alert";
import QrImg from '../assets/images/qr.svg'

const ChildComponent = ({ name, imageSrc, dob, id, qrCodeSrc, formatDate }) => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [picker, setPicker] = useState(dob);

  const validationSchema = yup.object().shape({
    name: yup.string().required("اسم الطفل مطلوب"),
  });
  const handleModalOpen = () => {
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
    reset({
      name,
      dob,
      qrCodeSrc,
    });
  };

  const handleDelete = () => {
    dispatch(deleteUser(id));
    reset({
      name,
      dob,
      qrCodeSrc,
    });
    handleModalClose();
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: name,
    },
  });

  const onSubmit = (data) => {
    const formData = new FormData();
    formData.append("fname", data?.name);
    formData.append("health_status", data?.healthStatus);
    formData.append("dob", picker);
    formData.append("_method", "put");
    dispatch(
      updateUser({
        id: id,
        user: formData,
      })
    );
    reset();
    handleModalClose();
  };

  return (
    <div className="child" dir="rtl">
      {imageSrc !== null && imageSrc !== undefined ? (
        <div className="position-relative">
        <img src={imageSrc} alt={name} onClick={handleModalOpen} />
        <img src={QrImg} className="qr-image"/>
          </div>
      ) : (
        <span
          class="position-relative badge rounded-pill text-capitalize mb-2"
          onClick={handleModalOpen}
        >
          {name?.substr(0, 1)}
          <img src={QrImg} className="qr-image"/>
        </span>
      )}
      <div className="child-info">
        <h6 className="text-capitalize">{name}</h6>
      </div>
      <Modal show={showModal} onHide={handleModalClose}>
        <Modal.Header closeButton dir="rtl">
          <Modal.Title>معلومات الطفل</Modal.Title>
        </Modal.Header>
        <Modal.Body dir="rtl">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-md-6 mb-3">
                <label className="form-label">اسم الطفل</label>
                <input
                  className="mt-2 form-control"
                  placeholder="ادخل اسم الطفل"
                  type="text"
                  defaultValue={name}
                  {...register("name")}
                />
                {errors.name && (
                  <small className="text-danger">{errors.name.message}</small>
                )}
              </div>
              <div className="col-md-6 mb-3">
                <label className="form-label"> تاريخ ميلاد الطفل</label>
                <Flatpickr
                  className="mt-2 form-control"
                  placeholder="ادخل  تاريخ ميلاد الطفل"
                  options={{
                    dateFormat: "Y-m-d",
                    maxDate: new Date(),
                  }} // Specify the desired date format
                  value={picker}
                  onChange={(e) => setPicker(formatDate(e))}
                />
              </div>
            </div>
            <QRCode
              size={256}
              style={{ height: "auto", maxWidth: "100%" }}
              value={qrCodeSrc}
              viewBox={`0 0 256 256`}
            />
            <div className="buttons mt-4">
              <button className="btn btn-primary btn-main ms-2" type="submit">
                تعديل
              </button>
              <button
                className="btn btn-danger"
                onClick={(e) =>{
                  e.preventDefault()
                  confirmAlert(id, handleDelete)
                }}
              >
                حذف
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ChildComponent;
