// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// ** Axios Imports
import axios from "axios";
import authHeader from "../utility";
import { errorAlert, successAlert } from "../utility/alert";

export const getKids = createAsyncThunk("kids/getKids", async (params) => {
  const response = await axios.get(
    "https://joycitybackend.bw-businessworld.com/api/v1/web/kids/list?with_paginate=no",
    { headers: authHeader() }
  );
  return {
    params,
    data: response.data.data,
    totalPages: response.data.data?.length,
    loading: true,
  };
});
export const getRes = createAsyncThunk("kids/getRes", async (params) => {
  const response = await axios.get(
    "https://joycitybackend.bw-businessworld.com/api/v1/web/reservation/list?with_paginate=no",
    { headers: authHeader() }
  );
  return {
    params,
    data: response.data.data,
    loading: true,
  };
});
export const getUser = createAsyncThunk("kids/getUser", async (id) => {
  const response = await axios.get(
    `https://joycitybackend.bw-businessworld.com/${id}`
  );
  return response.data.result;
});

export const addUser = createAsyncThunk(
  "kids/addUser",
  async (user, { dispatch, getState }) => {
    await axios
      .post(
        "https://joycitybackend.bw-businessworld.com/api/v1/web/kids/store",
        user,
        {
          headers: authHeader(),
        }
      )
      .then((res) => {
        successAlert();
      })
      .catch((err) => {
        errorAlert(err.response.data.messages[0]);
      });

    await dispatch(getKids(getState().kids.params));
    return user;
  }
);
export const addReservation = createAsyncThunk(
  "kids/addReservation",
  async (user, { dispatch, getState }) => {
    await axios
      .post(
        "https://joycitybackend.bw-businessworld.com/api/v1/web/reservation/store",
        user,
        {
          headers: authHeader(),
        }
      )
      .then((res) => {
        successAlert();
      })
      .catch((err) => {
        errorAlert(err.response.data.messages[0]);
      });

    await dispatch(getKids(getState().kids.params));
    await dispatch(getRes());
    return user;
  }
);
export const updateUser = createAsyncThunk(
  "kids/updateUser",
  async (params, { dispatch, getState }) => {
    await axios
      .post(
        `https://joycitybackend.bw-businessworld.com/api/v1/web/kids/update/${params?.id}`,
        params.user,
        {
          headers: authHeader(),
        }
      )
      .then((res) => {
        successAlert();
      })
      .catch((err) => {
        errorAlert(err.response.data.messages[0]);
      });

    await dispatch(getKids());
    return params.user;
  }
);

export const deleteUser = createAsyncThunk(
  "kids/deleteUser",
  async (id, { dispatch, getState }) => {
    await axios
      .delete(
        `https://joycitybackend.bw-businessworld.com/api/v1/web/kids/delete/${id}`,
        { headers: authHeader() }
      )
      .then((res) => {
        successAlert();
      })
      .catch((err) => {
        errorAlert(err.response.data.messages[0]);
      });
    await dispatch(getKids(getState().kids.params));
    return id;
  }
);
export const deleteRes = createAsyncThunk(
  "kids/deleteRes",
  async (id, { dispatch, getState }) => {
    await axios
      .delete(
        `https://joycitybackend.bw-businessworld.com/api/v1/web/reservation/delete/${id}`,
        { headers: authHeader() }
      )
      .then((res) => {
        successAlert();
      })
      .catch((err) => {
        errorAlert(err.response.data.messages[0]);
      });
    await dispatch(getRes());
    return id;
  }
);

export const kidsSlice = createSlice({
  name: "kids",
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    reservations: [],
    selectedUser: null,
    loading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getKids.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.params = action.payload.params;
        state.total = action.payload.totalPages;
        state.loading = action.payload.loading;
      })
      .addCase(getRes.fulfilled, (state, action) => {
        state.reservations = action.payload.data;
        state.params = action.payload.params;
        state.loading = action.payload.loading;
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload;
      });
  },
});

export default kidsSlice.reducer;
