import { Link } from "react-router-dom";
import Logo from "../assets/images/logo.png";
import { useDispatch, useSelector } from "react-redux";
import { getUserData } from "../utility";
import { useEffect } from "react";
const Home = () => {

  return (
    <>
      <div className="top-section pt-5 pb-5">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <img src={Logo} width={"300px"} />
              <h1 className="mt-4">مرحبا بك في مدينة المرح</h1>
              <p className="mt-4">
                مدينة المرح هي مكان رائع ومميز مصمم خصيصًا للأطفال حيث يمكنهم
                الاستمتاع واللعب بحرية. تُعد هذه المدينة مكانًا مميزًا لتنمية
                مهاراتهم الاجتماعية والحركية بطرق مبتكرة وتفاعلية. تتميز مدينة
                المرح بمجموعة متنوعة من الأنشطة والألعاب التي تهدف إلى توفير
                بيئة مليئة بالتسلية والتعلم.
              </p>
              <div className="d-flex justify-content-center">

              <button className="btn-register btn-in me-2 ms-2">
              <Link to={"/login"}>  تسجيل الدخول </Link>
              </button>
              <button className="btn-register me-2 ms-2">
                <Link to={"/register"}>سجل أطفالك</Link>
              </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
