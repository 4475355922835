import { Link, useNavigate } from "react-router-dom";
import Logo from "../assets/images/logo.png";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import "flatpickr/dist/themes/material_blue.css";
import cogoToast from "cogo-toast";
import { useDispatch } from "react-redux";
import { handleLogin } from "../redux/authentication";
import InputPasswordToggle from "../components/Input";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const validationSchema = yup.object().shape({
    password: yup.string().min(8, 'كلمة المرور يجب ان تكون مكونة من 8 محارف').required("كلمة المرور مطلوبة"),
    email: yup.string().email().required("البريد الالكتروني مطلوب"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      password: "",
      email: "",
    },
  });

  const onSubmit = (data) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("identifier", data.email);
    formData.append("password", data.password);
    axios
      .post(
        "https://joycitybackend.bw-businessworld.com/api/v1/web/auth/login",
        formData
      )
      .then((response) => {
        const data = {
          ...response.data,
          accessToken: response.data.access_token,
          refreshToken: response.data.access_token,
          username: response.data.user_name,
        };
        dispatch(handleLogin(data));
        navigate("/view");
      })
      .catch((err) => {
        console.log(err);
        cogoToast.error(err?.response?.data?.messages[0], {
          position: "bottom-left",
        });
        setLoading(false);
      });
  };
  return (
    <>
      <div className="top-section pt-5 pb-5">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <img src={Logo} width={"300px"} />
              <h1 className="mt-4">مرحبا بك في مدينة المرح</h1>
              <div className="bg-white form-wrap mt-5 p-4">
                <form
                  className="text-end"
                  dir="rtl"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <label className="form-label">البريد الالكتروني</label>
                      <input
                        className="mt-2 form-control"
                        placeholder="ادخل البريد الالكتروني"
                        type="email"
                        {...register("email")}
                      />
                      {errors.email && (
                        <small className="text-danger">
                          {errors.email.message}
                        </small>
                      )}
                    </div>
                    <div className="col-md-6 mb-3">
                      <label className="form-label">كلمة المرور</label>

                      <InputPasswordToggle
                        className="mt-2 form-control"
                        placeholder=""
                        {...register("password")}
                      />
                      {/* <input
                        className="mt-2 form-control"
                        placeholder="ادخل كلمة المرور"
                        type="password"
                        {...register("password")}
                      /> */}
                      {errors.password && (
                        <small className="text-danger">
                          {errors.password.message}
                        </small>
                      )}
                      <Link to="/forget" className="mt-3">
                        {" "}
                        <b>هل نسيت كلمة المرور؟</b>
                      </Link>
                    </div>

                    <button className="register-btn mt-4" type="submit">
                      {loading ? "يتم التسجيل.." : "تسجيل الدخول"}
                    </button>
                    <Link to="/register" className="mt-3">
                      {" "}
                      <b>ليس لديك حساب؟ </b>
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
