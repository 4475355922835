// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// ** Axios Imports
import axios from "axios";
import authHeader from "../utility";
import { errorAlert, successAlert } from "../utility/alert";

export const setEmail = createAsyncThunk("code/setEmail", async (email) => {
  return {
    email: email,
  };
});
export const setCode = createAsyncThunk("code/setCode", async (code) => {
  return {
    code: code,
  };
});
export const codeSlice = createSlice({
  name: "code",
  initialState: {
    email: null,
    code: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(setEmail.fulfilled, (state, action) => {
        state.email = action.payload.email;
      })
      .addCase(setCode.fulfilled, (state, action) => {
        state.code = action.payload.code;
      });
  },
});

export default codeSlice.reducer;
