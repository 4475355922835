import React, { Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Register from "./pages/Register";
import View from "./pages/View";
import Login from "./pages/Login";
import Forget from "./pages/Forget";
import Verfiy from "./pages/Verfiy";
import Reset from "./pages/Reset";

const App = () => {
  return (
    <Suspense>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} />
          <Route path="/view" element={<View />} />
          <Route path="/forget" element={<Forget />} />
          <Route path="/verify" element={<Verfiy />} />
          <Route path="/reset" element={<Reset />} />
        </Routes>
      </Router>
    </Suspense>
  );
};

export default App;
